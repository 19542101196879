.welcome {
  width: 100vw;
  height: calc(100vh - 67px);
  position: relative;
  margin-top: -16px;
}

.header {
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.publicInfo {
  position: absolute;
  top: 100px;
  right: 50px;
  background-color: white;
  color: rgb(21, 20, 84);
  z-index: 1000;
  padding: 30px;
  border: 1px solid rgb(229, 229, 229);
  border-radius: 5px;
}

.infoHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgb(229, 229, 229);
}

.infoHeader svg {
  font-size: 36px;
}

.infoHeader h2 {
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
}

.totalSuspects img {
  width: 150px;
  margin: 0 auto;
}

.totalSuspects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.totalSuspects span {
  font-size: 1.8rem;
  font-weight: 400;
}

.metric {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 10px 15px;
}

.metric span {
  font-size: 1.5rem;
  font-weight: 400;
  margin-left: 10px;
}
