.container {
  width: 100vw;
  padding: 0px 50px;
}

.navigateBox {
  display: flex;
  align-items: center;
}

.navigateBtn {
  background-color: transparent;
  border: none;
  color: #46a6ff;
  cursor: pointer;
  margin: 0px;
  padding: 5px;
}

.navigateBtn:hover {
  text-decoration: underline;
}

.titleBox h2 {
  font-size: 2rem;
  margin-left: 0px;
  padding: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.formContainer {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}

.formContainer h1 {
  font-size: 24px;
  text-align: center;
}

.propertiesSegment {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin-right: 40px;
  background-color: rgb(41, 43, 74);
  border: none;
  border-radius: 20px;
}

.detailsSegment {
  display: flex;
  flex-direction: column;
  width: 65%;
  margin-right: 40px;
  background-color: rgb(41, 43, 74);
  border: none;
  border-radius: 20px;
}

.botForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 60px;
}

.personaForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 60px;
}

.personaInput {
  display: flex;
  width: 100%;
  max-width: 350px;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
}

.personaInput input {
  flex-grow: 1;
  height: 40px;
  border-radius: 10px;
  border: none;
  color: white;
  padding: 0px 10px;
  background-color: #1c1f3c;
}

.personaInput p {
  color: red;
  font-size: 14px;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: -20px;
  left: 5px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 20px;
  }

  .formContainer {
    flex-direction: column;
  }

  .formSegment {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1400px) {
  .personaForm {
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .personaInput {
    width: calc(50% - 20px);
    margin-right: auto;
  }
}
