.numberSection {
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 92%;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;

  border: 2px solid #fff;
  border-radius: 15px;
  background-color: #6200ea;
  background-color: transparent;
  color: #fff;
}

.blurred {
  background-color: rgba(0, 0, 0, 0.5);
  transition: filter 0.3s ease, background-color 0.3s ease;
}

.item {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.item span {
  font-size: 46px;
  font-weight: bold;
  letter-spacing: 6px;
  margin-bottom: -10px;
}

.item p {
  letter-spacing: 3px;
  font-size: 22px;
  margin-bottom: -8px;
}

@media (max-width: 600px) {
  .numberSection {
    bottom: 10px;
    border-radius: 8px;
  }

  .item {
    margin: 0;
  }

  .item span {
    font-size: 16px;
    letter-spacing: 3px;
    margin: 0;
  }

  .item p {
    font-size: 12px;
    letter-spacing: 1px;
    margin: 0;
  }
}
